import axios from "../axios";

export const fetchUser = async () => {
    try {
        const response = await axios.get('/user');
        return response.data;
    } catch (error) {
        console.error('Ошибка при запросе:', error);
        throw error;
    }
};