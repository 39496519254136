import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

const LoginForm = ({ onLoginSuccess }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, {
                username: username,
                password: password,
            });

            if (response.status === 200) {
                const token = response.data.token;
                localStorage.setItem('jwt_token', token);
                toast.success('Успешный вход в систему');
                onLoginSuccess();
            }
        } catch (err) {
            if (err.response && err.response.data) {
                setError(err.response.data.error || 'Произошла ошибка при входе в систему.');
                toast.error(err.response.data.error || 'Произошла ошибка при входе в систему.');
            } else {
                setError('Не удалось подключиться к серверу.');
                toast.error('Не удалось подключиться к серверу.');
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container mt-5">
            <div className="row justify-content-center">
                <div className="col-12 col-md-6 col-lg-4">
                    <div className="card shadow-sm">
                        <div className="card-body">
                            <h2 className="card-title text-center mb-4">👁️‍🗨️ YOUMETRICS</h2>
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="username" className="form-label">Имя пользователя:</label>
                                    <input
                                        type="text"
                                        id="username"
                                        className="form-control"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                        required
                                        placeholder="Введите имя пользователя"
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="password" className="form-label">Пароль:</label>
                                    <input
                                        type="password"
                                        id="password"
                                        className="form-control"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                        placeholder="Введите пароль"/>
                                </div>
                                <button type="submit" className="btn btn-primary w-100" disabled={loading}>
                                    {loading ? 'Вход...' : 'Войти'}
                                </button>
                            </form>
                            {error && <div className="alert alert-danger mt-3">{error}</div>}

                            <hr/>

                            <p>По любым вопросам пишите в телеграм <a href="https://t.me/frescojacq" target="_blank">@frescojacq</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginForm;