import React from 'react';

const Dashboard = () => {
    return (
        <div className="container">
            <div className="dashboard-container">
                <p>По любым вопросам пишите в телеграм <a href="https://t.me/frescojacq" target="_blank">@frescojacq</a></p>
            </div>
        </div>
    );
};

export default Dashboard;
