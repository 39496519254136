import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import '../css/Navbar.css';
import {Button, Container, Nav, Navbar as BootstrapNavbar} from 'react-bootstrap';
import {fetchUser} from "../api/user";

const Navbar = ({ onLogout }) => {
    const [user, setUser] = useState([]);
    const [expanded, setExpanded] = useState(false);

    const loadUser = async () => {
        try {
            const data = await fetchUser();
            setUser(data);
        } catch (err) {
            console.log(err.message);
        }
    };

    useEffect(() => {
        loadUser();
    }, []);

    return (
        <BootstrapNavbar bg="primary" variant="dark" expand="lg" expanded={expanded} className="py-2">
            <Container>
                <BootstrapNavbar.Brand as={Link} to="/dashboard">Youcheck</BootstrapNavbar.Brand>
                <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)} />
                <BootstrapNavbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto d-flex align-items-center">
                        <Nav.Link as={Link} to="/add-task" onClick={() => setExpanded(false)}>Селекция</Nav.Link>
                    </Nav>
                    <div>
                        <span className="navbar-text me-3">
                            Limit: {user.usage} / {user.limit}
                        </span>
                        <Button variant="outline-light" onClick={() => { onLogout(); setExpanded(false); }}>
                            Выйти
                        </Button>
                    </div>
                </BootstrapNavbar.Collapse>
            </Container>
        </BootstrapNavbar>
    );
};

export default Navbar;
