import React from "react";
import TaskRow from "./TaskRow";
import { downloadTaskResult } from '../api/downloadTaskResult';

const TaskList = ({ tasks }) => (
    <table className="table table-bordered">
        <thead>
        <tr>
            <th scope="col">ID</th>
            <th scope="col">Total</th>
            <th scope="col">Good</th>
            <th scope="col">Bad</th>
            <th scope="col">Status</th>
            <th scope="col">Result</th>
        </tr>
        </thead>
        <tbody className="placeholder-glow">
        {tasks.map((task, index) => (
            <TaskRow key={index} task={task} onDownload={downloadTaskResult} />
        ))}
        </tbody>
    </table>
);

export default TaskList;