import React from "react";
import axios from "../axios";

const startDownload = async (e, task_id) => {
    e.preventDefault();

    try {
        axios.get(`/task/${task_id}/results/csv`, {
            responseType: 'blob'
        }).then((response) => {
            const href = URL.createObjectURL(response.data);

            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `task-${task_id}.csv`); //or any other extension
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        });
    } catch (e) {
        console.log(e)
    }
}

const TaskRow = ({ task, onDownload }) => (
    <tr>
        <th scope="row">{task.task_id}</th>
        <td>{task.status === 'done' ? task.total : <span className="placeholder col-12"></span>}</td>
        <td>{task.status === 'done' ? task.good : <span className="placeholder col-12"></span>}</td>
        <td>{task.status === 'done' ? task.bad : <span className="placeholder col-12"></span>}</td>
        <td>
            {task.status === 'pending' ? (
                <span className="badge text-bg-primary">{task.status}</span>
            ) : (
                <span className="badge text-bg-success">{task.status}</span>
            )}
        </td>
        <td>
            {task.status === 'done' ? (
                <a href="#" onClick={(e) => onDownload(e, task.task_id)}>Download</a>
            ) : (
                '---'
            )}
        </td>
    </tr>
);

export default TaskRow;