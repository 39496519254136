import React, {useEffect, useState} from 'react';
import axios from "../axios";
import {toast} from 'react-toastify';
import {fetchTasks} from "../api/tasks";
import {addTask} from '../api/addTask';
import TaskList from "./TaskList";

const AddTaskForm = () => {
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [channels, setChannels] = useState('');
    const [running, setRunning] = useState(false);
    const [error, setError] = useState('');
    const [taskId, setTaskId] = useState(null);
    const [status, setStatus] = useState([]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!channels.trim()) {
            setError('Пожалуйста, введите хотя бы одно имя канала.');
            toast.error('Пожалуйста, введите хотя бы одно имя канала.');
            return;
        }

        const sanitizedChannels = channels.split('\n').filter((line) => line.trim() !== "");

        try {
            const token = localStorage.getItem('jwt_token');
            if (!token) {
                setError('Необходима аутентификация. Пожалуйста, войдите в систему.');
                toast.error('Необходима аутентификация. Пожалуйста, войдите в систему.');
                setRunning(false);
                return;
            }

            try {
                const response = await addTask('youtube', sanitizedChannels);
                setTaskId(response.task_id);
                setRunning(true);
                setChannels('');
                loadTasks();
            } catch (err) {
                setError('Ошибка при добавлении задачи: ' + err.message);
            } finally {
                setLoading(false);
            }
        } catch (err) {
            console.error(err);
            if (err.response && err.response.status === 401) {
                setError('Неверный или истекший токен. Пожалуйста, выполните вход повторно.');
                toast.error('Неверный или истекший токен. Пожалуйста, выполните вход повторно.');
            } else if (err.response && err.response.data && err.response.data.error) {
                setError(err.response.data.error || 'Произошла ошибка при анализе каналов.');
                toast.error(err.response.data.error || 'Произошла ошибка при анализе каналов.');
            } else {
                setError('Не удалось подключиться к серверу.');
                toast.error('Не удалось подключиться к серверу.');
            }
        }
    };

    const loadTasks = async () => {
        try {
            const data = await fetchTasks();
            setTasks(data);
        } catch (err) {
            if (err.message) setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadTasks();
    }, []);

    useEffect(() => {
        if (!taskId) return; // Запускаем проверку, только если есть taskId

        const checkTaskStatus = async () => {
            try {
                const response = await axios.get(`/check-task`, {params: {taskId}});

                setStatus(response.data);

                if (response.data.total_units === response.data.processed_units) {
                    toast.success('Анализ завершился!');
                    setRunning(false);
                    loadTasks();
                    clearInterval(intervalId);
                }
            } catch (err) {
                console.error(err);
                setError('Ошибка при проверке статуса задачи.');
                toast.error('Ошибка при проверке статуса задачи.');
                setRunning(false);
                clearInterval(intervalId);
            }
        };

        const intervalId = setInterval(checkTaskStatus, 2000);

        checkTaskStatus();

        return () => clearInterval(intervalId);
    }, [taskId]);

    return (
        <div className="container">
            <div className="row mt-3">
                <div className="col-md-6">
                    <TaskList tasks={tasks}/>
                </div>

                <div className="col-md-6">
                    <div className="card mb-3">
                        <div className="card-body">
                            По любым вопросам пишите в телеграм <a href="https://t.me/frescojacq" target="_blank">@frescojacq</a>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <p><strong>Укажите на каждой строке ссылки на каналы, например:</strong></p>
                                    <p>
                                        https://www.youtube.com/channel/UC101o-vQ2iOj9vr00JUlyKw<br/>
                                        https://www.youtube.com/channel/UC-uoJpvCwnWSnlvPHKjWvfw<br/>
                                        https://www.youtube.com/channel/UCfycbDsIVOVM3Rh0zrWtqQg
                                    </p>
                                    <textarea
                                        className="form-control"
                                        value={channels}
                                        onChange={(e) => setChannels(e.target.value)}
                                        rows="10"
                                        required
                                    ></textarea>
                                </div>
                                <button className="btn btn-primary mt-3" type="submit" disabled={running}>
                                    {running ? 'Анализирую...' : 'Анализировать'}
                                </button>

                                {running &&
                                    <div className="progress mt-3">
                                        <div className="progress-bar"
                                             style={{width: (100 / status.total_units * status.processed_units) + '%'}}>
                                            обработано {status.processed_units} из {status.total_units}
                                        </div>
                                    </div>
                                }
                            </form>
                            {error && <div className="text-danger mt-3">Error: {error}</div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddTaskForm;
