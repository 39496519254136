import axios from "../axios";

export const downloadTaskResult = async (event, taskId) => {
    event.preventDefault();

    try {
        const response = await axios.get(`/task/${taskId}/results/csv`, {
            responseType: 'blob',
        });
        const href = URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `task-${taskId}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    } catch (err) {
        console.error('Ошибка при скачивании:', err);
        throw new Error('Ошибка при загрузке файла.');
    }
};