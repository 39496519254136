import axios from "../axios";

export const addTask = async (taskType, units) => {
    try {
        const response = await axios.post('/add-task', {
            task_type: taskType,
            units,
        });
        return response.data;
    } catch (error) {
        console.error('Ошибка при добавлении задачи:', error);
        throw error;
    }
};